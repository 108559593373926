@tailwind base;
@tailwind components;
@tailwind utilities;

#top-bar>nav>.active {
  @apply font-semibold;
}

@layer base {
  body {
    @apply text-lg;
  }
}

@layer components {
  .prose {
    @apply max-w-6xl;
  }
  .prose p code::before {
    content: '';
  }
  .prose p code::after {
    content: '';
  }
  .container {
    @apply px-4;
  }
}
